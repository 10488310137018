.background {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
