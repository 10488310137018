.payment-page {
    background: #e5ebf4;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.payment-page .table-footer {
    position: sticky;
    bottom: 0;
    display: flex;
    background: #fff;
    align-items: center;
    height: 50px !important;
    justify-content: flex-end;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, .12);
    padding: 0 2rem;
}

.payment-page .table-footer-inner {
    height: 40px;
    display: flex;
    align-items: center;
}

.payment-page table {
    width: 100%;
    font-size: 1rem;
    background: #fff;
    text-align: center;
}

.payment-page table thead {
    top: 0;
    position: sticky;
}


.payment-page table tr th {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
}


.payment-page table td.with-action {
    cursor: pointer;
}

.payment-page table td.with-icon {
    color: blue;
}

.payment-page table tr th input[type='checkbox'] {
    margin: 1rem;
}

.payment-page table tr td input[type='radio'] {
    margin: 0 .5em;
}

.payment-page table tr th,
.payment-page table tr td {
    padding: .4rem 0.1rem;
    font-size: 0.8em;
    text-align: left;
}

.payment-page table tr:nth-child(even) {
    background-color: #f2f7ff;
}

.payment-page table th,
.payment-page .table-footer {
    height: 32px;
    font-weight: 400;
    font-family: inherit;
    color: #a4a9ad;
}

