.manual-setup-data-option-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    width: 100%;
}

.manual-setup-data-option-group {
    width: 100%;
    border: 1px solid #a4a9ad;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    padding: 1rem .5rem;
}

.manual-setup-data-option-group-divider {
    height: 1px;
    width: calc(100% - 70px);
    background-color: #dddddd;
}

.manual-setup-data-option-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.manual-setup-data-option-inner input[type=radio] {
    flex: 0 0 70px;
}

.manual-setup-data-option {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
}

.manual-setup-data-option-item {
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    gap: 5px;
}

.manual-setup-data-option-item-name {
    color: #a4a9ad;
}

.manual-setup-data-option-item-data {
    color: #36363a;
}


.manual-setup-data-option-container table {
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.manual-setup-data-option-container table th {
    font-family: Roboto, sans-serif;
    height: 32px;
    flex-grow: 1;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: left;
    color: #a4a9ad;
}

.manual-setup-data-option-container table tr {
    border-top: 1px solid #ddd;
    align-self: stretch;
    flex-grow: 0;
    /* display: flex; */
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 0;
    gap: 0;
}

.manual-setup-data-option-container table tr:nth-child(even) {
    background-color: #f2f7ff;
}

.manual-setup-data-option-container table td {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #36363a;
    letter-spacing: 1px;
    text-align: left;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    word-break: break-all;
    max-width: 200px;

    border-left: none;
    border-right: 1px solid #ddd;
}

.manual-setup-data-option-container table td:first-child {
    border-left: 1px solid #ddd;
}

.manual-setup-data-option-container table * {
    text-align: left;
}
