.loading-spinner {
    border: 3px solid lightskyblue;
    border-bottom-color: lightgray;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: 0 1rem;
    animation: spin 1s linear infinite;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
