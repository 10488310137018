/* width */
.shop-autocomplete::-webkit-scrollbar {
    width: 12px;
}

/* Track */
.shop-autocomplete::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .12);
    background-clip: padding-box;
    border: 3px solid transparent;
    border-radius: 100px
}

/* Handle */
.shop-autocomplete::-webkit-scrollbar-thumb {
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    background: #0069d9;
    background-clip: padding-box;
    border: 3px solid transparent;
    border-radius: 100px
}