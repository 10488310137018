@import '~antd/dist/antd.css';

.App {
text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*background-color: lightblue;*/

}

.body{
  margin: 0;
  padding: 0;
}


.wide-modal .modal-dialog {
  max-width: 1000px;
}


