.modal-height {
    height: 80vh;
}

.role-form {
    height: 100%;
}

.role-form-table {
    overflow-y: scroll;
    height: 90%;
}

.form-buttons {
    margin-top: 2%;
    margin-inline: 5px;
}

.form-buttons-wrapper {
    text-align: center;
}