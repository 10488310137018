
.list_item_container_label_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    cursor: pointer;
    gap: 5px;
}

.list_item_container_label_container p {
    margin: 0;
}

.list_item_label_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.list_item_label_status {
    font-size: 10px;
    font-weight: 600;
}

.list_item_label_date {
    font-size: 14px;
    font-weight: 500;
}