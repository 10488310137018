.DamageInfo > div {
    border-bottom: 1px solid #f3f3f3;
}

.DamageInfo {
    border-bottom: none !important;
    gap: 0 !important;
}

.damage-info-inner {
    padding-block: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: 1rem;
    gap: .5em;
    font-family: Roboto, sans-serif;
    text-align: left;
}

.damage-info-inner .damage-info-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.damage-info-inner .damage-info-column .damage-info-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5em;
}

.damage-info-inner .damage-info-column .damage-info-item .damage-info-value,
.damage-info-inner .damage-info-column .damage-info-item .damage-info-name {
    text-transform: capitalize;
    flex: 1 1 49%;
    font-size: 0.8125em;
    line-height: 1;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 25px;
}

.damage-info-inner .damage-info-column .damage-info-item .damage-info-value {
    line-height: 25px;
    color: #36363a;
    background-color: #f2f4f4;
    padding: 0 1em;
    border-radius: 4px;
    white-space: nowrap;
    justify-content: space-between;
}

.damage-info-inner .damage-info-column .damage-info-item .damage-info-name {
    max-width: 165px;
    text-align: right;
    justify-content: flex-end;
    color: #a4a9ad;
}
.damage-info-container{
    padding-block: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    gap: 12px;
    font-family: Roboto, sans-serif;
    text-align: left;
}
.damage-info-row{
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
}
.damage-info-new-item{
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 13px;
}
.damage-info-item-value-container{
    color: #495057;
    background-color: #f2f4f4;
    border-radius: 4px;
    padding: 6px;
    flex: 1;
    width: 100%;
    text-align: right;
    min-height: 25px;
}
.damage-info-name-container{
    text-transform: capitalize;
    font-size: 13px;
    color: #A4A9AD;
    flex: 1;
}
.flex-row{
    display: flex;
    flex-direction: row;
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.blue-number-info{
    gap:4px;
    align-self: flex-end;
    margin-bottom: 4px;
}
.text-last{
    text-align-last: right;
}


@media screen and (max-width: 1200px) {

    .damage-info-inner .damage-info-column .damage-info-item {
        gap: 0;
        flex-direction: column;
    }

    .damage-info-inner .damage-info-column .damage-info-item .damage-info-value,
    .damage-info-inner .damage-info-column .damage-info-item .damage-info-name {
        height: auto;
        flex: 0 0 auto;
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    .damage-info-inner .damage-info-column .damage-info-item .damage-info-name {
        max-width: 100%;
    }

}

@media screen and (max-width: 1000px) {

    .damage-info-inner {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 769px) {


    .damage-info-inner {
        gap: .1em;
        grid-template-columns: repeat(1, 1fr);
    }

    .damage-info-inner .damage-info-column {
        gap: .1em;
    }

    .damage-info-inner .damage-info-column .damage-info-item {
        align-items: flex-start;
    }


    .damage-info-inner .damage-info-column .damage-info-item .damage-info-value,
    .damage-info-inner .damage-info-column .damage-info-item .damage-info-name {
        width: 100%;
        text-align: left;
        justify-content: space-between;
    }
}
.hide{
    display: none;
}
.info-icon-release-status:hover + .hide {
    display: block;
    color: rgb(255, 255, 255);;
    position: absolute;
    font-size: 0.75em;
    background: rgb(0, 168, 232);
    border-radius: 2px;
    padding: 0.5px 2px;
    transition: opacity 1s ease-in-out;
}
.tooltip-inner {
    font-size: 0.75rem;
    opacity: 1;
}
