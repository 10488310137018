/* For pdf */
.react-pdf__Document {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media only screen and (min-width: 1600px) {
    .react-pdf__Document {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.react-pdf__Page {
    /*margin-top: 10px;*/
}

.react-pdf__Page canvas,
.react-pdf__Page__svg,
.react-pdf__Page svg{
    width: 100% !important;
    height: auto !important;
}
