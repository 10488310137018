.react-switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}

.react-switch {
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: #eee;
    position: relative;
    transition: background-color 0.2s;
    border-radius: 35%;
}

.react-switch .react-switch-button {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    transition: 0.2s;
    background: #07aa35;
    /*box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);*/
    border-radius: 50%;
}

.react-switch-checkbox:checked + .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-checkbox:active + .react-switch-button {
    width: 30px;
}

.react-switch-labels {
    z-index: 0;
    height: 100%;
    font: 8pt "Segoe UI", Verdana, sans-serif;
}

.react-switch-labels span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}

.react-switch-labels span:first-child {
    left: 0;
}

.react-switch-labels span:last-child {
    right: 0;
}