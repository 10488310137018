.form-bill-details-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.form-bill-details-container-col {
    display: flex;
    gap: .7em;
    flex-wrap: wrap;
}